/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Aggregation {
  AGE = "AGE",
  ASPECT = "ASPECT",
  AUDIENCE = "AUDIENCE",
  AUTHOR = "AUTHOR",
  CATEGORY = "CATEGORY",
  COUNTRY = "COUNTRY",
  COUNTY = "COUNTY",
  DARK = "DARK",
  EMOTION = "EMOTION",
  FEED = "FEED",
  GENDER = "GENDER",
  GMB_REVIEW_RATING = "GMB_REVIEW_RATING",
  HASHTAG = "HASHTAG",
  MARITAL = "MARITAL",
  MEDIA_REFERENCE = "MEDIA_REFERENCE",
  OCCUPATION = "OCCUPATION",
  RATINGS = "RATINGS",
  REFERENCE = "REFERENCE",
  REGION = "REGION",
  REVIEW_PLATFORMS = "REVIEW_PLATFORMS",
  REVIEW_PRODUCT = "REVIEW_PRODUCT",
  SENTIMENT = "SENTIMENT",
  SOURCE = "SOURCE",
  STORE = "STORE",
  TIME = "TIME",
  TYPE = "TYPE",
}

export enum AlertInterval {
  D = "D",
  H = "H",
  M = "M",
}

export enum AlertOperator {
  AND = "AND",
  OR = "OR",
}

export enum AlertSource {
  all = "all",
  fb = "fb",
  ig = "ig",
  linkedin = "linkedin",
  media = "media",
  review = "review",
  tiktok = "tiktok",
  tw = "tw",
  yt = "yt",
}

export enum AlertThresholdAggregator {
  PER_MESSAGE_REACH = "PER_MESSAGE_REACH",
  PER_MESSAGE_SHARES = "PER_MESSAGE_SHARES",
  RISK = "RISK",
  VOLUME = "VOLUME",
}

export enum AlertType {
  AUTHOR = "AUTHOR",
  INFLUENCE = "INFLUENCE",
  REVIEW_VOLUME = "REVIEW_VOLUME",
  RISK = "RISK",
  VIRALITY = "VIRALITY",
  VOLUME = "VOLUME",
}

export enum AudienceSortField {
  NAME = "NAME",
}

export enum AudienceSource {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN",
  MEDIA = "MEDIA",
  TIKTOK = "TIKTOK",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
}

export enum BiDockPlatform {
  looker = "looker",
  powerbi = "powerbi",
  tableau = "tableau",
}

export enum CategoryKeywordOperator {
  NOT = "NOT",
  OR = "OR",
}

export enum ConditionOperation {
  CONTAINS = "CONTAINS",
}

export enum ConditionType {
  AUTHORS = "AUTHORS",
  CATEGORIES = "CATEGORIES",
  FEEDS = "FEEDS",
  SENTIMENT = "SENTIMENT",
  SOURCES = "SOURCES",
}

export enum Dark {
  DARK = "DARK",
  ORGANIC = "ORGANIC",
}

export enum DeliveryFrecuency {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
}

export enum EntityType {
  BRAND = "BRAND",
  FEED = "FEED",
}

export enum EvolutionInterval {
  DAY = "DAY",
  EIGHT_HOURS = "EIGHT_HOURS",
  FIVE_MINUTES = "FIVE_MINUTES",
  HOUR = "HOUR",
  MINUTE = "MINUTE",
  MONTH = "MONTH",
  YEAR = "YEAR",
}

export enum FeedMonitor {
  amazon_review = "amazon_review",
  brandwatch = "brandwatch",
  facebook = "facebook",
  gmb = "gmb",
  instagram = "instagram",
  linkedin = "linkedin",
  media = "media",
  review = "review",
  tiktok = "tiktok",
  twitter = "twitter",
  youtube = "youtube",
}

export enum FeedRecoverSource {
  amazon_reviews = "amazon_reviews",
  brandwatch = "brandwatch",
  facebook = "facebook",
  gmb = "gmb",
  instagram = "instagram",
  linkedin = "linkedin",
  media = "media",
  review = "review",
  tiktok = "tiktok",
  twitter = "twitter",
  twitter_full_archive = "twitter_full_archive",
  youtube = "youtube",
}

export enum FeedRecoverStatus {
  cancelled_by_quota = "cancelled_by_quota",
  error = "error",
  ignore = "ignore",
  pending = "pending",
  pending_approve = "pending_approve",
  pending_estimation = "pending_estimation",
  processed = "processed",
  processed_with_error = "processed_with_error",
  processing = "processing",
  rejected = "rejected",
}

export enum FeedType {
  KEYWORDS = "KEYWORDS",
  USERS = "USERS",
}

export enum FileUploadType {
  PRODUCT_LOCATION_METADATA = "PRODUCT_LOCATION_METADATA",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  UNKNOWN = "UNKNOWN",
}

export enum GroupsSort {
  COMMENTS = "COMMENTS",
  EVOLUTION = "EVOLUTION",
  FOLLOWERS = "FOLLOWERS",
  INFLUENCE = "INFLUENCE",
  LIKES = "LIKES",
  RATING = "RATING",
  REACH = "REACH",
  SHARES = "SHARES",
  VIEWS = "VIEWS",
  VOLUME = "VOLUME",
}

export enum LinkedinAccountType {
  Company = "Company",
  Member = "Member",
}

export enum LocationsMetadataSortField {
  NAME = "NAME",
}

export enum MentionType {
  BLOG = "BLOG",
  COMMENT = "COMMENT",
  FORUM = "FORUM",
  NEWS = "NEWS",
  POST = "POST",
  REELS = "REELS",
  REPLY = "REPLY",
  RETWEET = "RETWEET",
  REVIEW = "REVIEW",
  STORY = "STORY",
  TWEET = "TWEET",
  VIDEO = "VIDEO",
}

export enum MentionsOrder {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum MentionsSort {
  CLICKS = "CLICKS",
  COMMENTS = "COMMENTS",
  DATE = "DATE",
  DISLIKES = "DISLIKES",
  FOLLOWERS = "FOLLOWERS",
  IMPRESSIONS = "IMPRESSIONS",
  INFLUENCE = "INFLUENCE",
  LIKES = "LIKES",
  RATING = "RATING",
  REACH = "REACH",
  REACTIONS = "REACTIONS",
  REVIEW_PLATFORMS = "REVIEW_PLATFORMS",
  RISK_LEVEL = "RISK_LEVEL",
  SHARES = "SHARES",
  VIEWS = "VIEWS",
}

export enum Monitor {
  FACEBOOK = "FACEBOOK",
  GMB = "GMB",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN",
  METABASE = "METABASE",
  TIKTOK = "TIKTOK",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
}

export enum MonitorEngine {
  BRANDWATCH = "BRANDWATCH",
  SENTISIS = "SENTISIS",
}

export enum OfficialAccountEvolution {
  activity = "activity",
  comments = "comments",
  followers = "followers",
  likes = "likes",
  shares = "shares",
}

export enum OfficialAccountInterval {
  daily = "daily",
  fiveMinutes = "fiveMinutes",
  hourly = "hourly",
  minute = "minute",
  monthly = "monthly",
  yearly = "yearly",
}

export enum OfficialAccountMessagesOrder {
  asc = "asc",
  desc = "desc",
}

export enum OfficialAccountMessagesSort {
  comments = "comments",
  dislikes = "dislikes",
  likes = "likes",
  retweets = "retweets",
  shares = "shares",
  time = "time",
  views = "views",
}

export enum OfficialAccountSource {
  facebook = "facebook",
  instagram = "instagram",
  linkedin = "linkedin",
  tiktok = "tiktok",
  twitter = "twitter",
  youtube = "youtube",
}

export enum ProductExclusiveSources {
  MEDIA = "MEDIA",
}

export enum ProductStatus {
  NOT_PURCHASED = "NOT_PURCHASED",
  PURCHASED = "PURCHASED",
}

export enum ProductType {
  DRIVE = "DRIVE",
  EXPLORE = "EXPLORE",
  MAPS = "MAPS",
  RACE = "RACE",
  REVIEW = "REVIEW",
  REVIEW_BENCHMARK = "REVIEW_BENCHMARK",
}

/**
 * TOTEBOT - Recover launched from totebot
 * END_USER - Recover launched by an end user from front end
 * AUTOMATIC - Recover launched by the platform without user intervention
 */
export enum RecoverType {
  AUTOMATIC = "AUTOMATIC",
  END_USER = "END_USER",
  TOTEBOT = "TOTEBOT",
}

export enum ResultAction {
  EXCLUDE = "EXCLUDE",
  INCLUDE = "INCLUDE",
}

export enum ResultType {
  CATEGORIES = "CATEGORIES",
  SENTIMENT = "SENTIMENT",
}

export enum RuleKlasses {
  category = "category",
  contains = "contains",
  contains_cs = "contains_cs",
  hashtag = "hashtag",
  language = "language",
  location = "location",
  matches = "matches",
  retweets_of = "retweets_of",
  term_to_search = "term_to_search",
  tw_user = "tw_user",
  tw_user_pattern = "tw_user_pattern",
  url = "url",
}

export enum RuleSources {
  amazon_reviews = "amazon_reviews",
  fb = "fb",
  gmb = "gmb",
  ig = "ig",
  linkedin = "linkedin",
  news = "news",
  reviews = "reviews",
  tiktok = "tiktok",
  tw = "tw",
  yt = "yt",
}

export enum Sentiment {
  NEGATIVE = "NEGATIVE",
  OBJECTIVE = "OBJECTIVE",
  POSITIVE = "POSITIVE",
  UNKNOWN = "UNKNOWN",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum Source {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN",
  MEDIA = "MEDIA",
  REVIEW = "REVIEW",
  TIKTOK = "TIKTOK",
  TWITTER = "TWITTER",
  YOUTUBE = "YOUTUBE",
}

export enum UserRole {
  CLIENT_ADMIN = "CLIENT_ADMIN",
  NORMAL = "NORMAL",
}

export interface AddAccountProfileInput {
  src: OfficialAccountSource;
  feedId: string;
  productId: string;
  profile?: string | null;
  accountType?: LinkedinAccountType | null;
}

export interface AgeFiltersInput {
  or?: (string | null)[] | null;
}

export interface AlertInput {
  active?: boolean | null;
  authors?: string[] | null;
  categoryId?: string | null;
  categoryIds?: string[] | null;
  description?: string | null;
  email: string[];
  excludedCategoryIds?: string[] | null;
  excludedLocations?: string[] | null;
  excludedRegions?: string[] | null;
  feedIds?: string[] | null;
  hidden?: boolean | null;
  ignoreRetweets?: boolean | null;
  ignoreUsers?: string[] | null;
  interval?: AlertInterval | null;
  locations?: string[] | null;
  name: string;
  ratings?: number[] | null;
  regions?: string[] | null;
  sentiments?: string[] | null;
  sources?: AlertSource[] | null;
  threshold?: number | null;
  thresholds?: AlertThresholdInput[] | null;
  type: AlertType;
  minRisk?: number | null;
  maxRisk?: number | null;
  actionProtocol?: string | null;
  trackMessage?: boolean | null;
  minImpressions?: number | null;
  minShares?: number | null;
}

export interface AlertThresholdInput {
  metric: AlertThresholdAggregator;
  value: number;
  interval?: AlertInterval | null;
  operator: AlertOperator;
}

export interface AndOrNotOperator {
  or?: (any | null)[] | null;
  and?: (any | null)[] | null;
  not?: (any | null)[] | null;
}

export interface AudienceConditionInput {
  src: AudienceSource;
  users: string[];
  filename?: string | null;
}

export interface AudienceFiltersInput {
  or?: string[] | null;
  not?: string[] | null;
}

export interface AudienceInput {
  name: string;
  description?: string | null;
  criticality: number;
  conditions: AudienceConditionInput[];
  isVisible?: boolean | null;
}

export interface AudiencesFilterInput {
  name?: string | null;
  limit?: number | null;
  offset?: number | null;
  sort?: AudiencesSortInput | null;
}

export interface AudiencesSortInput {
  field: AudienceSortField;
  direction?: SortDirection | null;
}

export interface BiDockConfigConfigInput {
  platforms?: BiDockPlatform[] | null;
  activateTimezone?: boolean | null;
}

export interface CategoryInput {
  product: string;
  extended_category?: string | null;
  name: string;
  keywords?: (CategoryKeywordInput | null)[] | null;
  description?: string | null;
  riskLevel?: number | null;
}

export interface CategoryKeywordInput {
  words?: (string | null)[] | null;
  operator?: CategoryKeywordOperator | null;
}

export interface CategoryOperator {
  or?: (string | null)[] | null;
  or_additional?: (string | null)[] | null;
  and?: (string | null)[] | null;
  not?: (string | null)[] | null;
  only?: (string | null)[] | null;
}

export interface ConditionInput {
  type: ConditionType;
  operation: ConditionOperation;
  sentiments?: Sentiment[] | null;
  categories?: string[] | null;
  authors?: (string | null)[] | null;
  sources?: FeedMonitor[] | null;
  feeds?: string[] | null;
}

export interface CreateFeedInput {
  blocked?: boolean | null;
  logoUrl?: string | null;
  name: string;
  productId?: string | null;
  sourcesConfig?: FeedSourceConfigInput[] | null;
}

export interface CreateFeedRecoversInput {
  feedsIds: string[];
  sources: FeedRecoverSource[];
  since: any;
  until: any;
  recoverType?: RecoverType | null;
  messagesPriority?: number | null;
  maxMessages?: number | null;
}

export interface CreateNewListeningInput {
  product: CreateProductInput;
  feeds: CreateFeedInput[];
}

export interface CreateProductInput {
  analysisConfig?: ProductAnalysisConfigInput | null;
  brandId: string;
  countries?: string[] | null;
  languages?: string[] | null;
  name: string;
  status?: ProductStatus | null;
  type?: ProductType | null;
  exclusiveSource?: ProductExclusiveSources | null;
  description?: string | null;
}

export interface CreateProjectInput {
  name: string;
  clientId: string;
  logoUrl?: string | null;
  networks?: ProjectNetworkInput[] | null;
}

export interface CustomRuleInput {
  product: string;
  name: string;
  description?: string | null;
  conditions?: ConditionInput[] | null;
  results?: ResultInput[] | null;
  extendTo?: ExtendToInput | null;
}

export interface ExtendToInput {
  retweets?: boolean | null;
  commentsAndReplies?: boolean | null;
}

export interface FeedFiltersInput {
  or?: (string | null)[] | null;
  not?: (string | null)[] | null;
}

export interface FeedSourceConfigInput {
  isBlocked?: boolean | null;
  isIncluded?: boolean | null;
  rules?: SourceRuleInput[] | null;
  source: FeedMonitor;
  includeRt?: boolean | null;
  accounts?: string[] | null;
  adAccounts?: string[] | null;
  pages?: SourcePageConfigInput[] | null;
  locationsAccounts?: SourceLocationAccountConfigInput[] | null;
  companies?: string[] | null;
  file?: SourceFileConfigInput | null;
  blogIncluded?: boolean | null;
  forumIncluded?: boolean | null;
  newsIncluded?: boolean | null;
}

export interface FileDownloadURLInput {
  entityId: string;
  uploadType: FileUploadType;
}

export interface FileSignedURLInput {
  contentType: string;
  entityId: string;
  uploadType: FileUploadType;
}

export interface Filters {
  from: any;
  to: any;
  product: string;
  source?: Source | null;
  view?: string | null;
  byViewFilters?: boolean | null;
  age?: OrOperator | null;
  aspect?: AndOrNotOperator | null;
  author?: OrNotOperator | null;
  audience?: OrNotIdOperator | null;
  category?: CategoryOperator | null;
  device?: OrOperator | null;
  feed?: OrNotOperator | null;
  gender?: OrOperator | null;
  geoLocation?: GeoLocation | null;
  hashtag?: AndOrNotOperator | null;
  interest?: OrOperator | null;
  location?: OrNotOperator | null;
  maritalStatus?: OrOperator | null;
  mentions?: AndOrNotOperator | null;
  occupation?: OrOperator | null;
  reviewPlatform?: ReviewPlatform | null;
  reviewProduct?: ReviewProduct | null;
  sentiment?: OrNotOperator | null;
  store?: OrNotOperator | null;
  storeMetadata?: StoreMetadataOperator[] | null;
  text?: TextOperator | null;
  theme?: ThemeOperator | null;
  parentId?: OrOperator | null;
  messageId?: OrNotOperator | null;
  type?: TypeFilter | null;
  url?: OrOperator | null;
  mainMessage?: boolean | null;
  uncategorized?: boolean | null;
  followers?: FollowersRange | null;
  isDark?: boolean | null;
  rating?: RatingOperator | null;
  isEdited?: boolean | null;
  withOriginal?: boolean | null;
  isOwned?: boolean | null;
}

export interface FollowersRange {
  min?: any | null;
  max?: any | null;
}

export interface GenderFiltersInput {
  or?: (Gender | null)[] | null;
}

export interface GeoLocation {
  country?: OrNotOperator | null;
  county?: OrNotOperator | null;
  region?: OrNotOperator | null;
  locality?: OrNotOperator | null;
}

export interface GeoLocationFiltersInput {
  country?: OrNotOperator | null;
  county?: OrNotOperator | null;
  region?: OrNotOperator | null;
  locality?: OrNotOperator | null;
}

export interface LocationsMetadataFilterInput {
  text?: string | null;
  limit?: number | null;
  offset?: number | null;
  sort?: LocationsMetadataSortInput | null;
}

export interface LocationsMetadataSortInput {
  field: LocationsMetadataSortField;
  direction?: SortDirection | null;
}

export interface MentionInsightsAuthorInput {
  name: string;
  username: string;
  count: string;
}

export interface MentionInsightsInput {
  fromDate: string;
  toDate: string;
  totalVolume: string;
  volumeEvolution: number;
  mentionsTotalReach: string;
  reachEvolution: number;
  uniqueUsers: string;
  uniqueUsersEvolution: number;
  negativeSentimentData: MentionInsightsSentimentInput;
  objectiveSentimentData: MentionInsightsSentimentInput;
  positiveSentimentData: MentionInsightsSentimentInput;
  mentionsBySource: MentionInsightsSourceDataInput[];
  mostActiveAuthor?: MentionInsightsAuthorInput | null;
  mostReachAuthor?: MentionInsightsAuthorInput | null;
  peakTime: string;
  peakVolume: string;
  peakReach: string;
  peakAspect: string;
  peakLink: string;
  topSharesFormattedTweet?: MentionInsightsTopTweetInput | null;
  topReachFormattedTweet?: MentionInsightsTopTweetInput | null;
  topLikesPost?: MentionInsightsTopPostInput | null;
  topCommentsPost?: MentionInsightsTopPostInput | null;
  topCategories: (MentionInsightsTopCategoryInput | null)[];
  topAspects: (MentionInsightsTopAspectInput | null)[];
  mentionsEmotions: (MentionInsightsMentionEmotionInput | null)[];
  contextMessageTemplate?: string | null;
}

export interface MentionInsightsMentionEmotionInput {
  emotion: string;
  count: string;
}

export interface MentionInsightsSentimentInput {
  mentions?: string | null;
  mentionsPerc?: number | null;
  sentiment?: Sentiment | null;
  evolution?: number | null;
  impact?: string | null;
}

/**
 * INPUTS FOR getMentionInsights
 */
export interface MentionInsightsSourceDataInput {
  source: string;
  count: string;
  pctg: number;
}

export interface MentionInsightsTopAspectInput {
  aspect: string;
  count: string;
  pctg: number;
}

export interface MentionInsightsTopCategoryInput {
  name: string;
  count: string;
  pctg: number;
  evolution: number;
}

export interface MentionInsightsTopPostInput {
  likes?: string | null;
  comments?: string | null;
  text: string;
  source: string;
}

export interface MentionInsightsTopTweetInput {
  shares?: string | null;
  reach?: string | null;
  text: string;
  username: string;
}

export interface NewsletterDeliveryInput {
  frecuency?: DeliveryFrecuency | null;
  hour?: number | null;
}

export interface NewsletterInput {
  productId: string;
  name?: string | null;
  description?: string | null;
  categoryIds?: string[] | null;
  emails?: string[] | null;
  delivery?: NewsletterDeliveryInput | null;
  active?: boolean | null;
}

export interface OrNotIdOperator {
  or?: string[] | null;
  not?: string[] | null;
}

export interface OrNotOperator {
  or?: (string | null)[] | null;
  not?: (string | null)[] | null;
}

export interface OrOperator {
  or?: (string | null)[] | null;
}

export interface ProductAnalysisConfigInput {
  sector: string;
  topicFocus: string;
}

export interface ProductInput {
  sentimentRounding?: boolean | null;
  name?: string | null;
  countries?: string[] | null;
  languages?: string[] | null;
  biDockConfig?: BiDockConfigConfigInput | null;
}

/**
 * Twitter -> token, tokenSecret, userId, username
 * Facebook -> token, expiration
 * Instagram -> token, expiration, username, userId
 * GMB -> token, tokenSecret, expiration
 * Brandwatch -> token, expiration
 */
export interface ProjectNetworkInput {
  source: FeedMonitor;
  token: string;
  tokenSecret?: string | null;
  expiration?: any | null;
  username?: string | null;
  userId?: string | null;
}

export interface RatingOperator {
  or?: (number | null)[] | null;
}

export interface ResultInput {
  type: ResultType;
  action: ResultAction;
  sentiment?: Sentiment | null;
  categories?: string[] | null;
}

export interface ReviewPlatform {
  or?: (string | null)[] | null;
  not?: (string | null)[] | null;
}

export interface ReviewProduct {
  or?: (string | null)[] | null;
  not?: (string | null)[] | null;
}

export interface SendMentionEmailsInput {
  emails: string[];
  comments?: string | null;
  mention: SendMentionEmailsMessageInput;
}

export interface SendMentionEmailsMessageInput {
  source: Source;
  createdAt: any;
  text: string;
  name?: string | null;
  username?: string | null;
  url: string;
  avatarUrl?: string | null;
  thumbnailUrl?: string | null;
  followers?: number | null;
  reach?: number | null;
  retweets?: number | null;
  shares?: number | null;
  views?: number | null;
  clicks?: number | null;
  impressions?: number | null;
  ctr?: number | null;
  likes?: number | null;
  comments?: number | null;
  categories?: string[] | null;
}

export interface SentimentFiltersInput {
  or?: (Sentiment | null)[] | null;
}

export interface SignedURLInput {
  entityExtension: string;
  entityId: string;
  entityType: EntityType;
}

export interface SourceFileConfigInput {
  name: string;
  invalidRows?: string[] | null;
  deleteFile?: boolean | null;
}

export interface SourceLocationAccountConfigInput {
  id: string;
  name: string;
}

export interface SourcePageConfigInput {
  profile: string;
  webhooks?: boolean | null;
}

export interface SourceRuleInput {
  parameter: string;
  klass: RuleKlasses;
  operator: number;
  src: RuleSources;
}

export interface StoreMetadataOperator {
  key: string;
  values: string[];
}

export interface SummaryInsightsInput {
  volume?: number | null;
  volumeEvolution?: number | null;
  uniqueUsers?: number | null;
  uniqueUsersEvolution?: number | null;
  reach?: number | null;
  reachEvolution?: number | null;
  mentions?: SummaryInsightsMentionInput | null;
  parsedSourceDistribution?: string | null;
  negative?: SummaryInsightsSentimentInput | null;
  objective?: SummaryInsightsSentimentInput | null;
  positive?: SummaryInsightsSentimentInput | null;
  parsedCategoriesByEvolution?: string | null;
}

/**
 * INPUTS FOR getSummaryInsights
 */
export interface SummaryInsightsMentionInput {
  date?: string | null;
  mentionsNumber?: number | null;
}

export interface SummaryInsightsSentimentInput {
  mentions?: number | null;
  mentionsPerc?: number | null;
  sentiment?: Sentiment | null;
  evolution?: number | null;
  impact?: number | null;
}

export interface TextFiltersInput {
  ands?: ((string | null)[] | null)[] | null;
  ors?: ((string | null)[] | null)[] | null;
  nots?: ((string | null)[] | null)[] | null;
}

export interface TextOperator {
  ors?: ((string | null)[] | null)[] | null;
  and?: (string | null)[] | null;
  not?: (string | null)[] | null;
}

export interface ThemeFiltersInput {
  or?: (string | null)[] | null;
}

export interface ThemeInput {
  product: string;
  name: string;
  categories?: string[] | null;
}

export interface ThemeOperator {
  or?: (string | null)[] | null;
  only?: (string | null)[] | null;
}

export interface TypeFilter {
  or?: MentionType[] | null;
}

export interface UpdateFeedInput {
  logoUrl?: string | null;
  name?: string | null;
  sourcesConfig?: FeedSourceConfigInput[] | null;
}

export interface UpdateMessagesInput {
  sentiment?: Sentiment | null;
  addCategories?: string[] | null;
  replaceCategories?: string[] | null;
  removeCategories?: string[] | null;
  spam?: boolean | null;
  applyToResponses?: boolean | null;
  applyToRts?: boolean | null;
}

export interface UpdateMessagesTargets {
  filters?: Filters | null;
  count?: number | null;
}

export interface UpdateProjectInput {
  name?: string | null;
  logoUrl?: string | null;
  networks?: ProjectNetworkInput[] | null;
}

export interface UpdateUserInput {
  name?: string | null;
  email: string;
  timezone?: string | null;
  currentPassword?: string | null;
  newPassword?: string | null;
  language?: string | null;
}

export interface ViewFiltersInput {
  feeds?: FeedFiltersInput | null;
  themes?: ThemeFiltersInput | null;
  audiences?: AudienceFiltersInput | null;
  categories?: CategoryOperator | null;
  sentiments?: SentimentFiltersInput | null;
  genders?: GenderFiltersInput | null;
  ages?: AgeFiltersInput | null;
  maritalStatuses?: OrOperator | null;
  interests?: OrOperator | null;
  devices?: OrOperator | null;
  occupations?: OrOperator | null;
  texts?: TextFiltersInput | null;
  aspects?: AndOrNotOperator | null;
  authors?: OrNotOperator | null;
  mentions?: AndOrNotOperator | null;
  followers?: FollowersRange | null;
  hashtags?: AndOrNotOperator | null;
  types?: TypeFilter | null;
  locations?: OrNotOperator | null;
  geoLocations?: GeoLocationFiltersInput | null;
  stores?: OrNotOperator | null;
  storesMetadata?: StoreMetadataOperator[] | null;
  ratings?: RatingOperator | null;
  isDark?: boolean | null;
}

export interface ViewInput {
  name: string;
  product: string;
  themes?: string[] | null;
  main?: boolean | null;
  filters?: ViewFiltersInput | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
